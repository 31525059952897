$dkDray: #363030;
.quotes-Corner {
  background: #f1ff7d;
  padding: 20px 20px 10px;
  position: relative;
  box-shadow: 0px 4px 5px grey;
  img {
    height: 50px;
    top: 0px;
    position: absolute;
    left: -20px;
  }

  .qtitle {
    font-family: "karla-bold";
    margin-left: 45px;
    font-size: 30px;
    color: $dkDray;
  }
  .qdesc {
    color: $dkDray;
  }

  .pauthor,
  .pchapter {
    font-weight: bold;
    font-size: 14px;
    color: $dkDray;
  }
}
.qauthor {
  text-align: left;
}
.qchapter {
  text-align: right;
}
.qfooter {
  background: #f1ff7d;
  display: inline-block;
  padding: 0;
}
.text-right {
  text-align: right;
}

@media (max-width: 479px) {
  .quotes-Corner {
    .qtitle {
      font-size: 20px;
    }
    .qdesc {
      font-size: 17px;
      font-family: inherit;
    }
  }
}