body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.textContent {
	line-height: 1.7em;
    font-size: 20px;
    color: #383838;
    word-break: break-word;
}
.yog {
  color: #80ccea;
}
.lica {
  color: orange;
}