* {
  scroll-behavior: smooth;
}
$FONT_PATH: "../assets/fonts/";
@font-face {
  font-family: 'metropolis-bold';
  src: url($FONT_PATH + 'metropolis-bold.otf');
  font-display: swap;
}
body {
  font-family: "Calibri","system-ui",sans-serif !important;
  font-weight: 700;
  font-size: 18px;
  color: #231f20;
  margin: 0;
  padding: 0;
}
h1 {
  font-family: 'metropolis-bold';
  font-size: 40px !important;
  line-height: 54px;
  color: #231f20;
}
h2 {
  font-family: 'metropolis-bold';
  font-size: 34px;
  line-height: 38px;
  color: #231f20 !important;
}
.font-weight-bold {
  font-family: 'metropolis-bold';
}
.card-title {
  font-size: 16px;
  font-weight: bold;
  color: #231f20;
  padding-left: 5px;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.app-top-container {
  max-width: 1366px;
  margin: 0 auto;
}
.app-container {
  min-height: 400px;
}
.removePadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}