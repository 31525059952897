$colorWhite: #fff;
$border_color: #b78c33;

footer {
	margin-top: 5px;
}
.padding-left-0 {
	padding-left: 0 !important;
}
footer.nb-footer {
	background: #222;
	border-top: 4px solid #b78c33;
	position: absolute;
	left: 0;
	right: 0;
	.bottom-link {
		color: #7ca8ee;
	}
	.bottom-link:hover {
		color: blue;
	}
	.flast-text {
		color: #fff;
	}
	.about {
		margin: 0 auto;
		max-width: 1170px;
		text-align: center;
		p {
			font-size: 15px;
			color: $colorWhite;
			margin-top: 30px;
		}
		.youtube-icon {
			width: 38px;
			height: 38px;
			background: #ed1f24;
			border-radius: 50%;
		}
		// .social-media {
		// 	margin-top: 15px;
		// 	ul {
		// 		li {
        //             padding: 1px;
		// 			a {
		// 				display: inline-block;
		// 				width: 35px;
		// 				height: 35px;
		// 				border-radius: 50%;
		// 				font-size: 16px;
		// 				margin-right: 10px;

		// 				.fb-icon {
		// 					width: 32px;
		// 				}
		// 				.twitter-icon {
		// 					width: 35px;
		// 				}
						
		// 			}
		// 		}
		// 	}
		// }
	}
	.social-media {
		li {
			display: inline-block;
		}
	}
	.footer-info-single {
		margin-top: 30px;
		.title {
			color: $colorWhite;
			text-transform: uppercase;
			font-size: 14px;
			border-left: 4px solid #b78c33;
			padding-left: 5px;
		}
		ul {
			li {
				a {
					display: block;
					color: $colorWhite;
					padding: 2px 0;
					font-size: 14px;
					&:hover {
						color: $border_color;
					}
				}
			}
		}
		p {
			font-size: 13px;
			line-height: 20px;
			color: $colorWhite;
		}
	}
	.copyright {
		margin-top: 15px;
		background: #000;
		padding: 7px 0;
		color: $colorWhite;
		font-size: 14px;
		p {
			margin: 0;
			padding: 0;
		}
	}
}
.back-to-top-link {
	height: 40px;
	width: 40px;
	background: white;
	border-radius: 50%;
	margin: 0 auto;
}
.arrow-top {
	position: relative;
	top: 12px;
	right: 7px;
}
.topArrow {
	position: relative;
	left: 13px;
}

.about {
	button, img {
		margin-right: 10px;
	}
}
.fb-span {
	iframe {
		vertical-align: middle;
	}
}
.soc-button {
	background-color: transparent;
	border: none; padding: 0px; 
	font: inherit; color: inherit;
	cursor: pointer; 
	outline: none;
}

@media (max-width: 480px) {
	.footerFilter {
		display: none;
	}
	.bottom-link {
		display: block;
	}
}