$color_dkblue: #022238;
$color_orange: #444444;
$navbarTop: #222222;

.navbar {
  border-radius: 0 !important;
}
.navbar-top {
  background: $navbarTop;
}
.navbar-dark {
  padding:0 !important;
}

.nav-link,
.close-menu,
.navbar-toggler {
  color: #fff !important;
}

.nav-slider.toggling {
  transition: transform 0.35s, opacity 0.35s;
}

.navbar-toggler {
  float: left;
}
.features-inner-container {
    z-index: 1;
}

.features-inner-container,
.pricing-inner-container {
  display: none;
}
.sub-menu-head {
  font-size: 18px;
  font-weight: bold;
  color:#fff;
  border-bottom: 2px solid #5c5959;
  padding-bottom: 5px;
}
.sub-menu-lists {
  list-style-type: none;
  padding: 0;
  line-height: 30px;
  font-size: 15px;
  padding-top: 5px;
  cursor: pointer;
  a {
      color: #fff;
      text-decoration: none;
  }
}
.sub-menu-lists li:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: #fff;
}

.affix {
    top: 0;
    width: 100%;
    z-index: 9999 !important;
}

@media (min-width: 768px) {
    .navbar-top {
        padding-left: 10px;
    }

  .navbar-toggler,
  .close-menu {
      display: none;
  }
  .nav-item {
      border-right: 1px solid #bbb;
      font-size: 17px;
  }
  .active-item .featuresLink,
  .active-item .pricingLink {
      background: $color_orange;
      color: #fff !important;
      &:hover {
        color: #fff !important;
      }
  }
  .active-item .features-inner-container,
  .active-item .pricing-inner-container {
      display: block;
  }
  .nav-link:hover {
      color: #fff !important;
  }
  .active-item a:hover {
      color: inherit !important;
  }

  .features-inner-container,
  .pricing-inner-container {
      background: $color_orange;
      width: 100%;
      position: absolute;
      right: 5px;
      padding-top: 15px;
      padding-bottom: 15px;
  }
  .navbar-dark {
      display: flex;
      position: relative;
      max-width: 1366px;
      margin: 0 auto;
  }
  .caret-icon:after {
      content: "\25bc";
  }
  .active-item .caret-icon:after {
      content: "\25b2";
  }
  .caret-icon {
      font-size: 12px;
      padding-left: 5px;
  }
}
.navbar-brand-item {
    text-decoration: none;
    font-size: 23px;
    padding-top: 2px;
}
@media (max-width: 767px) {
  .navbar-toggler {
      margin-top: 5px;
      border: none !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
  }
  .navbar-brand-item {
      position: relative;
      display: block;
  }
  .nav-slider {
      opacity: 0;
      position: fixed;
      z-index: 200;
      background-color: #333333;
  }

  .nav-slider .navbar-nav {
      margin-top: 20px;
  }

  .nav-slider .navbar-nav .nav-item {
      float: none;
  }

  .nav-slider .navbar-nav .nav-item .nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
  }

  .nav-slider .navbar-nav .nav-item+.nav-item {
      margin-left: 0;
  }

  .navbar-top.has-active-menu {
      overflow: hidden;
  }

  .nav-mask {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.25s, width 0s 0.25s, height 0s 0.25s;
  }

  .has-active-menu .nav-mask {
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.25s;
  }

  /* next */
  .nav-slider {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      top: 0;
      left: 0;
      transform: translateX(-100%);
  }

  .has-active-menu .nav-slider {
      opacity: 1;
      transform: translateX(0);
  }

  .close-menu {
      position: relative;
      right: 15px;
      font-size: 30px;
      text-align: right;
  }

  .active-item .features-inner-container,
  .active-item .pricing-inner-container {
      display: block;
      padding-left: 15px;
  }

  .arrow-up {
      display: none;
  }

  .features-inner-container ul { 
      padding-left: 15px;
      line-height: 30px;
  }
  .active-item a.nav-link {
      background: forestgreen;
  }
  .menu-container .nav-item{
      width: 100%;
      position: relative;
  }
  .menu-container .nav-link{
      font-size: 18px;
  }
  .sub-menu-head {
      font-size: 17px;
      margin-top: 10px;
      color: orange;
      font-weight: bold;
  }
  .sub-menu-lists {
      color: #fff;
  }
  .navbar-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .nav-slider {
      width: 300px;
      transform: translateX(-300px);
  }
  .caret-icon:after {
      content: "\002b";
      font-size: 30px;
      position: absolute;
      top: -2px;
      right: 10px;
  }
  .active-item .caret-icon:after {
      content: "\002d";
      font-size: 30px;
      position: absolute;
      top: -4px;
      right: 14px;
  }
}

@media (max-width: 767px) {
    .navbar-dark .navbar-toggler {
        font-size: 1.6rem ;
        z-index: 9999;
        position: relative;
    }
}